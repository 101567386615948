import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditInventory = ({ token }) => {
  const { inventoryId } = useParams();
  const [data, setData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    axios
      .get(`${apiUrl}/inventory/generate-qrcode-data/${inventoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching inventory data:", error));
  }, [inventoryId, apiUrl, token]);

  const handleSave = () => {
    axios
      .put(`${apiUrl}/update-inventory/${inventoryId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => alert("Inventory updated successfully"))
      .catch((error) => console.error("Error updating inventory:", error));
  };

  if (!data) {
    return <div>Loading...</div>;
  }
  // console.log(data);
  return (
    <div>
      <h1>Edit {data.product_name}</h1>
      <input
        type="text"
        value={data.quantity}
        onChange={(e) => setData({ ...data, quantity: e.target.value })}
      />
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default EditInventory;
