import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import attentionCircle from "./attention-circle.png";

const AlertIcon = () => (
  <img src={attentionCircle} alt="Alert Icon" className="alertIcon" />
);

const AlertList = ({ token }) => {
  const [alarms, setAlarms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const fetchAlarms = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      const startOfMonth = new Date(selectedMonth);
      startOfMonth.setDate(1);
      const endOfMonth = new Date(selectedMonth);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);

      const response = await fetch(
        `${apiUrl}/statistics/alert_list?user_id=5&month=${selectedMonth.getMonth() + 1}&year=${selectedMonth.getFullYear()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
      }

      const data = await response.json();
      const updatedAlarms = data.map((item) => {
        const daysTillExpiration = Math.max(item.days_till_expiration, 0); // Ensure days_till_expiration is not negative
        const expDate = calculateExpirationDate(daysTillExpiration);
        return {
          ...item,
          expiration_date: expDate,
          days_till_expiration: daysTillExpiration,
          status: categorizeUrgency(daysTillExpiration),
        };
      });

      updatedAlarms.sort((a, b) => a.days_till_expiration - b.days_till_expiration);

      setAlarms(updatedAlarms);
    } catch (error) {
      setError(`Error fetching alarms: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, apiUrl, selectedMonth]);

  useEffect(() => {
    fetchAlarms();
  }, [fetchAlarms]);

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
  };

  const calculateExpirationDate = (daysLeft) => {
    const today = new Date();
    const expirationDate = new Date(today);
    expirationDate.setDate(today.getDate() + Math.max(daysLeft, 0)); // Ensure daysLeft is not negative
    return expirationDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  const categorizeUrgency = (daysLeft) => {
    if (daysLeft < 0) {
      return "Expired";
    } else if (daysLeft >= 0 && daysLeft <= 10) {
      return "Urgent";
    } else if (daysLeft > 10 && daysLeft <= 20) {
      return "Medium";
    } else {
      return "Fine";
    }
  };

  const getProgressBarColor = (status) => {
    switch (status) {
      case "Urgent":
        return "#02A412";
      case "Medium":
        return "#59DC5E";
      case "Fine":
        return "#92FE9D";
      case "Expired":
        return "#cccccc";
      default:
        return "#cccccc";
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="alerts-container">
      <div className="header">
        <AlertIcon />
        <h2>Alert List</h2>
        <div className="date-range-picker">
          <label>for </label>
          <DatePicker
            selected={selectedMonth}
            onChange={handleMonthChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
          />
        </div>
      </div>
      <div className="alert-list">
        {alarms.length > 0 ? (
          alarms.map((alarm) => (
            <div key={alarm.inventory_id} className={`alert-item ${alarm.status}`}>
              <img
                src={alarm.profile_pic || "default-product-image.png"}
                alt={alarm.product_name}
                className="product-image"
              />
              <div className="alert-info">
                <h3>{alarm.product_name}</h3>
                <p>Expiration Date: {alarm.expiration_date}</p>
                <div className="progress-bar">
                  <div
                    className="progress-bar-fill"
                    style={{
                      width: `${(alarm.days_till_expiration / 30) * 100}%`,
                      backgroundColor: getProgressBarColor(alarm.status),
                    }}
                  ></div>
                </div>
                <div className="status-info">
                  <p>Status: {alarm.status}</p>
                  <p>Days Left: {alarm.days_till_expiration}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-alerts">
            <p>No alerts found for the selected month.</p>
            <p className="placeholder-alert">Placeholder Alert</p>
            <p className="placeholder-alert">Placeholder Alert</p>
            <p className="placeholder-alert">Placeholder Alert</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertList;
