import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import "./Inventory.css";

function Inventory({ token }) {
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const fileInputRef = useRef(null); // Ref for the file input
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("product_name");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const fetchInventoryData = useCallback(async () => {
    if (!token) return;
    try {
      const response = await fetch(`${apiUrl}/products/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      data.sort(
        (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
      );
      setInventoryData(data);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  }, [token, apiUrl]);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  const handleFileChange = async (event) => {
    setIsLoading(true); // Start loading when the file is selected
    let file = event.target.files[0];

    try {
      // Check if the file is HEIC
      if (file.type === "image/heic" || file.name.endsWith(".heic")) {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/png",
        });

        file = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".png"),
          {
            type: "image/png",
          }
        );
        console.log("HEIC image converted to PNG.");
      }

      // Compress image to ensure it's under 1 MB
      if (file.size > 1 * 1024 * 1024) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        let compressedFile = file;
        while (compressedFile.size > 1 * 1024 * 1024) {
          compressedFile = await imageCompression(compressedFile, options);
        }

        setSelectedFile(compressedFile);
        console.log(
          `Compressed file size: ${(compressedFile.size / 1024).toFixed(2)} KB`
        );
      } else {
        setSelectedFile(file);
        console.log(`File size: ${(file.size / 1024).toFixed(2)} KB`);
      }
    } catch (error) {
      console.error("Error processing the image:", error);
      alert("Failed to process the image. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading after processing is complete
    }
  };
  const handleAddInventoryClick = async () => {
    if (!selectedFile) {
      alert("Please select an image file to upload.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(`${apiUrl}/inventory/upload-invoice-photo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Navigate to the confirmation page with extracted data
      navigate("/inventory_edit", { state: { products: data.items } });
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to process invoice.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditItem = (item) => {
    navigate(`/inventory_detail/${item.brand}/${item.product_name}`);
  };

  const filteredInventoryData = inventoryData.filter((item) =>
    item[searchCriteria].toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredInventoryData.length / itemsPerPage);

  const paginatedInventoryData = Array.from({ length: totalPages }, (_, i) =>
    filteredInventoryData.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
  );

  return (
    <div className="inventory-container">
      <h1>Inventory Management</h1>
      <div className="file-upload-container">
        <label className="custom-file-upload">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*, .heic" // Allow more formats including .heic
            onChange={handleFileChange}
          />
          Drag and drop a photo here or click to upload
        </label>
        {selectedFile && <span className="file-name">{selectedFile.name}</span>}
        {selectedFile && !isLoading && (
          <button
            className="add-inventory-button"
            onClick={handleAddInventoryClick}
          >
            Add Inventory Item
          </button>
        )}
        {isLoading && <p>Uploading... Please wait.</p>}
      </div>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Search inventory..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="select-container">
          <div className="select-arrow"></div>
          <select
            className="select-element"
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
          >
            <option value="product_name">Product Name</option>
            <option value="brand">Brand</option>
            <option value="expiration_date">Expiration Date</option>
            <option value="aisle_number">Aisle Number</option> {/* New field */}
            <option value="category">Category</option> {/* New field */}
          </select>
        </div>
      </div>
      <div className="table-container">
        <div className="inventory-table">
          <table>
            <thead>
              <tr>
                <th>Brand</th>
                <th>Product Name</th>
                <th>Expiration Date</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Selling Price</th> {/* New column */}
                <th>Margin</th> {/* New column */}
                <th className="action-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedInventoryData[currentPage - 1]?.map((item, index) => (
                <tr key={index}>
                  <td>{item.brand}</td>
                  <td>{item.product_name}</td>
                  <td>{item.expiration_date || "No expiration date"}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unit_price}</td>
                  <td>{item.selling_price || "N/A"}</td> {/* New field */}
                  <td>{item.margin ? `${item.margin}%` : "N/A"}</td>{" "}
                  {/* New field */}
                  <td className="action-cell">
                    <button
                      className="edit-button"
                      onClick={() => handleEditItem(item)}
                    >
                      <FaPencilAlt />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
