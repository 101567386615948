import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineGraph = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Extract dates and waste savings from data, or set default values
      const dates = Object.keys(data);
      const wasteSavings = Object.values(data);

      // Log dates and wasteSavings for debugging
      console.log('Dates:', dates);
      console.log('Waste Savings:', wasteSavings);

      // Destroy existing chart if it exists
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      // Create a new chart instance
      chartRef.current.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dates.length ? dates : ['No Data'], // Show 'No Data' if no dates are available
          datasets: [
            {
              label: 'Waste Savings ($)',
              data: wasteSavings.length ? wasteSavings : [0], // Show 0 if no waste savings data
              borderColor: '#3B7302',
              backgroundColor: 'rgba(59, 115, 2, 0.2)',
              borderWidth: 3,
              tension: 0.1,
              fill: false,
            }
          ]
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Sale Date',
                color: '#333',
                font: {
                  weight: 'bold',
                },
              },
              grid: {
                display: true,
                drawOnChartArea: true,
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0,
              },
            },
            y: {
              title: {
                display: true,
                text: 'Waste Savings ($)',
                color: '#333',
                font: {
                  weight: 'bold',
                },
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                callback: function(value) {
                  // Hide the tick at value 1
                  if (value === 1) {
                    return '';
                  }
                  return value;
                },
              },
              grid: {
                display: true,
                drawOnChartArea: true,
                // Custom callback to hide the grid line at y=1
                drawBorder: true,
                drawTicks: true,
                lineWidth: function(context) {
                  if (context.tick.value === 1) {
                    return 0; // Hide the grid line at y=1
                  }
                  return 1;
                },
              },
            }
          },
          plugins: {
            legend: {
              labels: {
                color: '#333',
                font: {
                  weight: 'bold',
                },
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => `Waste Savings: $${context.raw}`,
              },
            },
          },
        }
      });
    }
  }, [data]);

  return (
    <div className="line-graph">
      <canvas ref={chartRef} />
    </div>
  );
};

export default LineGraph;
