import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./InventoryEdit.css"; // Import a CSS file for styling

function InventoryEdit({ token }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { products } = location.state || []; // Extract products from route state
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const [editedProducts, setEditedProducts] = useState([]);

  // Initialize editedProducts with all fields from products
  useEffect(() => {
    if (products.length > 0) {
      const initializedProducts = products.map((product) => {
        return {
          product_name: product.product_name || "",
          brand: product.brand || "",
          quantity: product.quantity || 0,
          expiration_date: product.expiration_date || "",
          case_quantity: product.case_quantity || 0,
          unit_type: product.unit_type || "",
          unit_price: product.unit_price || 0,
          case_price: product.case_price || 0,
          total_price: product.total_price || 0,
          selling_price: product.selling_price || 0,
        };
      });
      setEditedProducts(initializedProducts);
    }
  }, [products]);

  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...editedProducts];
    updatedProducts[index][field] = value;
    setEditedProducts(updatedProducts);
  };

  const handleAddRow = () => {
    const newProduct = {
      product_name: "",
      brand: "",
      quantity: 0,
      expiration_date: "",
      case_quantity: 0,
      unit_type: "",
      unit_price: 0,
      case_price: 0,
      total_price: 0,
      selling_price: 0,
    };
    setEditedProducts([...editedProducts, newProduct]);
  };

  const handleDeleteRow = (index) => {
    const updatedProducts = editedProducts.filter((_, i) => i !== index);
    setEditedProducts(updatedProducts);
  };

  const handleConfirmClick = async () => {
    try {
      editedProducts.forEach((product, index) => {
        console.log(
          `Product ${index} - Expiration Date Type:`,
          typeof product.expiration_date
        );
        console.log(
          `Product ${index} - Expiration Date Value:`,
          product.expiration_date
        );
      });
      const response = await fetch(`${apiUrl}/inventory/confirm-inventory`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: editedProducts }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert("Inventory successfully added!");
      navigate("/inventory");
    } catch (error) {
      console.error("Error confirming inventory:", error);
      alert("Failed to add inventory.");
    }
  };

  const getInputType = (key) => {
    if (key.toLowerCase().includes("date")) return "date";
    if (
      key.toLowerCase().includes("quantity") ||
      key.toLowerCase().includes("price") ||
      key.toLowerCase().includes("margin")
    )
      return "number";
    return "text";
  };

  return (
    <div className="inventory-edit-container">
      <h1>Edit and Confirm Inventory Items</h1>
      <div className="products-list">
        {editedProducts.map((item, index) => (
          <div key={index} className="product-item">
            {Object.keys(item).map((key) => (
              <div key={key} className="form-group">
                <label>{key.replace(/_/g, " ").toUpperCase()}:</label>
                <input
                  type={getInputType(key)}
                  value={item[key]}
                  onChange={(e) =>
                    handleInputChange(index, key, e.target.value)
                  }
                />
              </div>
            ))}
            <button
              className="delete-btn"
              onClick={() => handleDeleteRow(index)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className="action-buttons">
        <button onClick={handleAddRow}>Add New Item</button>
        <button onClick={handleConfirmClick}>
          Confirm and Add to Inventory
        </button>
      </div>
    </div>
  );
}

export default InventoryEdit;
