import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const PieChart = ({ totalInventoryValue = 0, wastePreventedValue = 0 }) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (chart) {
        chart.destroy();
      }

      const hasData = wastePreventedValue > 0 || totalInventoryValue > 0;

      setChart(new Chart(ctx, {
        type: 'doughnut',  // Doughnut chart for a hollow circle
        data: {
          labels: ['Waste Prevented Value', 'Remaining Inventory Value'],
          datasets: [{
            label: 'Inventory vs Waste Savings',
            data: hasData ? [wastePreventedValue, totalInventoryValue - wastePreventedValue] : [1, 1],
            backgroundColor: hasData ? ['#6D9660', '#C0D2AE'] : ['#cccccc', '#eeeeee'],  // Dark green/light green or grey
            borderColor: hasData ? ['#6D9660', '#C0D2AE'] : ['#cccccc', '#eeeeee'],  // Dark green/light green or grey
            borderWidth: 2,
            borderRadius: 10,  // Rounded corners
            cutout: '50%',  // Hollow circle size
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'left',  // Place legend on the left
              labels: {
                boxWidth: 10,  // Width of the legend color box
                color: '#333', // Color of the legend text
                font: {
                  weight: 'lighter',  // Make the legend text bolder
                  size: '10px'
                },
              },
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem) {
                  const value = tooltipItem.raw;
                  const label = tooltipItem.label === 'Waste Prevented Value' ? 'Waste Prevented Value' : 'Remaining Inventory Value';
                  return hasData ? `${label}: $${value.toFixed(2)}` : 'No Data Available';
                }
              }
            },
          }
        },
      }));
    }
  }, [totalInventoryValue, wastePreventedValue]);

  return (
    <div className="pie-chart">
      <canvas ref={chartRef} />
    </div>
  );
};

export default PieChart;
