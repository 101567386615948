import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Create the AuthContext
const AuthContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

// Custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap your application and provide authentication context
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle login
  const login = async (username, password) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
  
    try {
      // Make the request to the /login endpoint
      const response = await axios.post(`${apiUrl}/auth/login`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Logged in Successfully");
  
      // Extract the authentication token and user ID from the response
      const { access_token, user_id } = response.data;
      console.log("Token:", access_token);
      console.log("User ID:", user_id);
  
      setIsLoggedIn(true); // Set the isLoggedIn state to true upon successful login
      return response.data; // Return any data you want to pass to the login success handler
    } catch (err) {
      console.error('Login error:', err);
      throw new Error('Login failed. Please check your credentials.');
    }
  };

  // Function to handle logout
  const logout = () => {
    setIsLoggedIn(false); // Set the isLoggedIn state to false upon logout
  };

  // Value to be provided by the context
  const value = {
    isLoggedIn,
    login,
    logout,
  };

  // Return the AuthContext Provider with the provided value and children components
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
