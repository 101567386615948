import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";


function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const handleLogin = async () => {
    const token = btoa(`${username}:${password}`);
    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setToken(data.access_token);
      navigate("/home");
    } catch (error) {
      console.error("Error logging in:", error);
      alert(
        "Login unsuccessful. Please check your username and password and try again."
      );
    }
  };

  const handlePasswordReset = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert(
        "If an account with that email exists, a password reset link has been sent."
      );
      setIsResettingPassword(false); // Jump back to login form
    } catch (error) {
      console.error("Error requesting password reset:", error);
      alert("Error requesting password reset. Please try again later.");
    }
  };

  const handleGoBack = () => {
    setIsResettingPassword(false);
  };

  return (
    <div className="login-container">
      
      <div className="login-box">
        {isResettingPassword ? (
          <div className="reset-password-box">
            <h2>Reset Password</h2>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handlePasswordReset}>Send Reset Link</button>
            <span className="go-back" onClick={handleGoBack}>
              Back to Login
            </span>
          </div>
        ) : (
          <div>
            <h2>Login</h2>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
            <span
              className="forgot-password"
              onClick={() => {
                setIsResettingPassword(true);
              }}
            >
              Forgot Password?
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
