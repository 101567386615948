import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const QRCodeRedirectHandler = ({ token }) => {
  const { inventoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate(`/edit-inventory/${inventoryId}`);
    } else {
      navigate(`/view-inventory/${inventoryId}`);
    }
  }, [inventoryId, navigate, token]);

  return null; // Render nothing while redirecting
};

export default QRCodeRedirectHandler;
