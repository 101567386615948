import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ViewInventory = () => {
  const { inventoryId } = useParams();
  const [data, setData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    axios
      .get(`${apiUrl}/inventory/generate-qrcode-data/${inventoryId}`)
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching inventory data:", error));
  }, [inventoryId, apiUrl]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{data.product_name}</h1>
      <p>Quantity: {data.quantity}</p>
      <p>Brand: {data.brand}</p>
      <p>
        Expiration Date: {new Date(data.expiration_date).toLocaleDateString()}
      </p>
    </div>
  );
};

export default ViewInventory;
