import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import "../Inventory/Inventory.css";

function InventoryDetail({ token }) {
  const { brand, productName } = useParams();
  const [inventoryData, setInventoryData] = useState([]);
  const [editedItems, setEditedItems] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const navigate = useNavigate();

  const fetchInventoryDetail = async () => {
    if (!token) return;
    try {
      const encodedBrand = encodeURIComponent(brand);
      const encodedProductName = encodeURIComponent(productName);
      const response = await fetch(
        `${apiUrl}/inventory/products?brand=${encodedBrand}&product_name=${encodedProductName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const today = new Date().toISOString().split("T")[0];
      const filteredData = data.filter(
        (item) => !item.expiration_date || item.expiration_date >= today
      );

      setInventoryData(filteredData);

      // Redirect to inventory page if there are no items left
      if (filteredData.length === 0) {
        navigate("/inventory");
      }
    } catch (error) {
      console.error("Error fetching inventory details:", error);
      // Redirect to inventory page if there's an error
      navigate("/inventory");
    }
  };

  useEffect(() => {
    fetchInventoryDetail();
  }, [token, apiUrl, brand, productName]);

  const handleInputChange = (e, inventory_id) => {
    const { name, value } = e.target;

    setEditedItems((prevState) => ({
      ...prevState,
      [inventory_id]: {
        ...prevState[inventory_id],
        [name]: value === "" ? "" : Math.max(0, Number(value)),
      },
    }));
  };

  const handleSaveAllClick = async () => {
    // Validate that no quantity fields are empty
    for (const inventory_id in editedItems) {
      const updatedData = editedItems[inventory_id];
      if (updatedData.quantity === "" || updatedData.quantity === undefined) {
        alert("Quantity cannot be left empty.");
        return; // Stop further execution
      }
    }

    try {
      const updatePromises = Object.keys(editedItems).map(
        async (inventory_id) => {
          const updatedData = editedItems[inventory_id];
          const response = await fetch(
            `${apiUrl}/inventory/update-inventory/${inventory_id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(updatedData),
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        }
      );

      await Promise.all(updatePromises);

      // Display an alert after successful save
      alert("All changes have been saved successfully!");

      fetchInventoryDetail();
    } catch (error) {
      console.error("Error updating inventory:", error);
    }
  };

  const handleDeleteClick = async (inventory_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      try {
        const response = await fetch(`${apiUrl}/inventory/${inventory_id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        alert("Item deleted successfully");

        await fetchInventoryDetail();

        // If there are no items left, navigate back to the inventory page
        if (inventoryData.length === 0) {
          navigate("/inventory");
        }
      } catch (error) {
        console.error("Error deleting inventory item:", error);
        // Redirect to inventory page in case of an error during deletion
        navigate("/inventory");
      }
    }
  };

  const handleCancelClick = () => {
    navigate("/inventory");
  };

  return (
    <div className="inventory-container">
      <h1>
        Inventory Details for {brand} - {productName}
      </h1>
      <div className="inventory-actions">
        <button onClick={handleSaveAllClick}>Save All Changes</button>
        <button className="cancel-button" onClick={handleCancelClick}>
          Cancel
        </button>
      </div>
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              <th>Brand</th>
              <th>Product Name</th>
              <th>Expiration Date</th>
              <th>Date Added</th>
              <th>Quantity</th>
              <th>Case Quantity</th> {/* New column */}
              <th>Unit Price</th> {/* New column */}
              <th>Case Price</th> {/* New column */}
              <th>Total Price</th> {/* New column */}
              <th>Selling Price</th> {/* New column */}
              <th>Margin</th> {/* New column */}
              <th className="action-header">Actions</th>
            </tr>
          </thead>
          <tbody>
            {inventoryData.map((item) => (
              <tr key={item.inventory_id}>
                <td>{item.brand}</td>
                <td>{item.product_name}</td>
                <td>{item.expiration_date || ""}</td>
                <td>{item.date_added || ""}</td>
                <td>
                  <input
                    type="number"
                    name="quantity"
                    value={
                      editedItems[item.inventory_id]?.quantity !== undefined
                        ? editedItems[item.inventory_id].quantity
                        : item.quantity
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                    required
                  />
                </td>
            
                <td>
                  <input
                    type="number"
                    name="case_quantity"
                    value={
                      editedItems[item.inventory_id]?.case_quantity !==
                      undefined
                        ? editedItems[item.inventory_id].case_quantity
                        : item.case_quantity
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="unit_price"
                    value={
                      editedItems[item.inventory_id]?.unit_price !== undefined
                        ? editedItems[item.inventory_id].unit_price
                        : item.unit_price
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="case_price"
                    value={
                      editedItems[item.inventory_id]?.case_price !== undefined
                        ? editedItems[item.inventory_id].case_price
                        : item.case_price
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="total_price"
                    value={
                      editedItems[item.inventory_id]?.total_price !== undefined
                        ? editedItems[item.inventory_id].total_price
                        : item.total_price
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="selling_price"
                    value={
                      editedItems[item.inventory_id]?.selling_price !==
                      undefined
                        ? editedItems[item.inventory_id].selling_price
                        : item.selling_price
                    }
                    onChange={(e) => handleInputChange(e, item.inventory_id)}
                  />
                </td>
                <td>{item.margin || ""}</td>
                <td className="action-cell">
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(item.inventory_id)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InventoryDetail;
