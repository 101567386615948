import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./PasswordReset.css";

const PasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";

  // const logActivity = async (type, details) => {
  //   try {
  //     await fetch(`${apiUrl}/log`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         details: details,
  //         timestamp: new Date().toISOString(),
  //       }),
  //     });
  //   } catch (error) {
  //     console.error("Error logging activity:", error);
  //   }
  // };

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      setMessage("Invalid token");
      // logActivity("invalid_token", { token });
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/auth/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, new_password: newPassword }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Password reset successful");
        localStorage.removeItem("token");
        navigate("/login");
        // logActivity("password_reset_successful", { token });
      } else {
        alert(data.detail || "An error occurred");
        // logActivity("password_reset_failed", { token, error: data.detail });
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      // logActivity("password_reset_error", { token, error: error.message });
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Reset Your Password</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="new_password">New Password:</label>
          <input
            type="password"
            id="new_password"
            name="new_password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button type="submit" value="Reset Password">
            Reset Password
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default PasswordReset;
