import React, { useState, useEffect, useCallback } from "react";
import AlertList from "../../components/AlertList/AlertList";
import LineGraph from "./LineGraph";
import PieChart from "./PieChart";
import "./home.css";

function Home({ token }) {
  const [selectedPeriod, setSelectedPeriod] = useState("total");
  const [totalInventoryValue, setTotalInventoryValue] = useState(0);
  const [wasteSavings, setWasteSavings] = useState({
    totalWasteSaving: 0,
    sales: [],
  });
  const [upcomingExpirations, setUpcomingExpirations] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  // Common fetch function
  const fetchData = useCallback(
    async (endpoint, setter) => {
      try {
        const response = await fetch(`${apiUrl}/statistics/${endpoint}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data);
      } catch (err) {
        setError(`Error fetching data: ${err.message}`);
      }
    },
    [apiUrl, token]
  );

  // Fetch data when component mounts or when the token changes
  useEffect(() => {
    if (token) {
      fetchData("total_item", (data) => setTotalItems(data.totalItems));
      fetchData("total_inventory_value", (data) =>
        setTotalInventoryValue(data.totalInventoryValue)
      );
      fetchData("upcoming_expirations", (data) =>
        setUpcomingExpirations(data.upcomingExpirationCount)
      );
      // fetchWasteSavings();
    }
  }, [fetchData, token]);

  const handlePeriodChange = (period) => {
    if (period !== selectedPeriod) {
      setSelectedPeriod(period);
    }
  };

  // Function to filter sales data based on the selected period
  const filterDataByPeriod = (sales, period) => {
    const today = new Date();
    let startDate;

    switch (period) {
      case "past6months":
        startDate = new Date();
        startDate.setMonth(today.getMonth() - 6);
        break;
      case "past3months":
        startDate = new Date();
        startDate.setMonth(today.getMonth() - 3);
        break;
      case "past1month":
        startDate = new Date();
        startDate.setMonth(today.getMonth() - 1);
        break;
      case "total":
        startDate = new Date(0); // no start date for 'total', include all data
        break;
      default:
        startDate = new Date(0); // default case also includes all data
    }

    return sales.filter((sale) => new Date(sale.sale_time) >= startDate);
  };

  // Aggregate data for LineGraph with waste savings
  const aggregatedData = wasteSavings.sales.reduce((acc, sale) => {
    const saleDate = sale.sale_time.split("T")[0]; // Extract date part from sale_time
    if (!acc[saleDate]) {
      acc[saleDate] = 0;
    }
    acc[saleDate] += sale.price; // Accumulate waste saving based on price
    return acc;
  }, {});

  // Ensure dates are sorted
  const sortedAggregatedData = Object.fromEntries(
    Object.entries(aggregatedData).sort(
      ([dateA], [dateB]) => new Date(dateA) - new Date(dateB)
    )
  );

  return (
    <div className="home-container">
      <h1>Welcome to Sustain-a-Plate!</h1>

      <div className="alert-list">
        <AlertList token={token} />
      </div>

      <div className="rectangular-area-container">
        <div className="rectangular-area total-inventory">
          <h3>Inventory Value</h3>
          <p>${totalInventoryValue.toFixed(2)}</p>
        </div>

        <div className="rectangular-area waste-savings">
          <h3>Waste Savings</h3>
          <p>${wasteSavings.totalWasteSaving.toFixed(2)}</p>
        </div>

        <div className="rectangular-area total-items">
          <h3>Item Number</h3>
          <p>{totalItems}</p>
        </div>

        <div className="rectangular-area upcoming-expirations">
          <h3>Upcoming Expirations</h3>
          <p>{upcomingExpirations}</p>
        </div>
      </div>

      <div className="subnavigation">
        <button
          className={selectedPeriod === "total" ? "selected" : ""}
          onClick={() => handlePeriodChange("total")}
        >
          Total
        </button>
        <button
          className={selectedPeriod === "past6months" ? "selected" : ""}
          onClick={() => handlePeriodChange("past6months")}
        >
          Past 6 Months
        </button>
        <button
          className={selectedPeriod === "past3months" ? "selected" : ""}
          onClick={() => handlePeriodChange("past3months")}
        >
          Past 3 Months
        </button>
        <button
          className={selectedPeriod === "past1month" ? "selected" : ""}
          onClick={() => handlePeriodChange("past1month")}
        >
          Past 1 Month
        </button>
      </div>

      {/* Line Graph and Pie Chart components */}
      <div className="graph-container">
        <div className="line-graph">
          {isLoading ? (
            <div>Loading Graph...</div>
          ) : (
            <LineGraph data={sortedAggregatedData} period={selectedPeriod} />
          )}
        </div>
        <div className="pie-chart">
          <PieChart
            totalInventoryValue={totalInventoryValue}
            wastePreventedValue={wasteSavings.totalWasteSaving}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
